import React, {FunctionComponent, useEffect, useRef, useState} from "react"
import {useQuery, useQueryClient} from "react-query"
import {InvestorOnboardingType} from "../onboarding/onboarding.type"
import InvestorDashboardSummary from "./summary/summary.component"
import {CurrencyOpenApi} from "../../../generated"
import {FilterType} from "../../../tech/filter/filter.type"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import InvestorDashboardTabs from "./tabs/tabs.component"
import InvestorDashboardInvestments from "./investments/investments.component"
import InvestorDashboardSummaryOverview from "./summary/overview/overview.component"
import EmptyState from "../../../tech/layout/empty/empty.component"
import InvestorDashboardHead from "./head/head.component"
import {QUERY__INVESTOR_DASHBOARD, QUERY_KEY__INVESTOR_DASHBOARD} from "./dashboard.query"
import {isErroneous, showLoadingAnimation} from "../../../tech/query/query.util"
import {DEFAULT_TARGET_CURRENCY} from "./dashboard.config"
import IndividualInvestmentPopup from "../../investment/individual/popup/popup.component"
import {IndividualInvestmentPopupStateType} from "../../investment/individual/popup/popup.type"
import InvestorDashboardHeadNoInvestments from "./head/head-no-investments.component"
import {useApp} from "../../../tech/app/context/app.context"
import Meta from "../../../tech/meta/meta.component"
import styles from "./dashboard.module.sass"

type InvestorDashboardProps = {
    onboarding: InvestorOnboardingType
}

const InvestorDashboard: FunctionComponent<InvestorDashboardProps> = ({ onboarding }) => {
    const app = useApp()
    const queryClient = useQueryClient()
    const [targetCurrency] = useState<CurrencyOpenApi>(DEFAULT_TARGET_CURRENCY)
    const [filterTypes, setFilterTypes] = useState<FilterType[]>([])
    const [filterTypesUpdateSource, setFilterTypesUpdateSource] = useState<FilterTypeUpdateSource>()
    const [filterOpenedHead, setFilterOpenedHead] = useState<boolean>(false)
    const [filterOpenedTable, setFilterOpenedTable] = useState<boolean>(false)
    const [mobileSelection, setMobileSelection] = useState<MobileSelectionType>("SUMMARY")
    const [
        individualInvestmentPopup,
        setIndividualInvestmentPopup
    ] = useState<IndividualInvestmentPopupStateType>({ type: "CLOSED" })

    const investmentsRef = useRef<HTMLDivElement>(null)

    const updateMobileSelection = (selection: MobileSelectionType) => setMobileSelection(selection)

    const updateFilterTypes = (updatedFilterTypes: FilterType[]) => setFilterTypes(updatedFilterTypes)

    const parameters = {
        filterTypes,
        targetCurrency
    }
    const result = useQuery(QUERY__INVESTOR_DASHBOARD(
        app.fetchClient,
        {
            keyFunction: QUERY_KEY__INVESTOR_DASHBOARD,
            parameters
        }
    ))

    const invalidateDashboardQuery = async () => {
        await queryClient.invalidateQueries(QUERY_KEY__INVESTOR_DASHBOARD(parameters), {
            refetchActive: true,
            refetchInactive: true,
        })
    }

    useEffect(() => {
        if (result.data && filterTypes.length > 0 && filterTypesUpdateSource === "TABLE") {
            investmentsRef.current!.scrollIntoView({ behavior: "smooth" })
        }
    }, [result.data, filterTypes.length, filterTypesUpdateSource])

    const title = "Investment Performance"
    const filter = {
        deals: result.data ? result.data.unfilteredDeals : [],
        filterTypes,
        updateFilterTypes,
        showExitedLiveFilter: true,
        showInvestmentTypeFilter: true
    }

    const updateFilterTypesAndSource = (updatedFilterTypes: FilterType[], source: FilterTypeUpdateSource) => {
        updateFilterTypes(updatedFilterTypes)
        setFilterTypesUpdateSource(source)
    }

    return (
        <>
            <Meta titleExtension="Investor Dashboard"/>
            {showLoadingAnimation(result) && <LoadingDots/>}
            {result.data && (
                <div className={styles.dashboard}>
                    <IndividualInvestmentPopup
                        state={individualInvestmentPopup}
                        updateState={setIndividualInvestmentPopup}
                        invalidateDashboardQuery={invalidateDashboardQuery}
                    />
                    {result.data.unfilteredInvestmentsCount > 0 ? (
                        <>
                            <InvestorDashboardHead
                                title={title}
                                filter={{
                                    ...filter,
                                    opened: filterOpenedHead,
                                    updateOpened: (opened: boolean) => setFilterOpenedHead(opened)
                                }}
                                updateIndividualInvestmentPopup={(state: IndividualInvestmentPopupStateType) => setIndividualInvestmentPopup(state)}
                                updateFilterTypesAndSource={updateFilterTypesAndSource}
                            />
                            <InvestorDashboardTabs<MobileSelectionType>
                                currentSelection={mobileSelection}
                                tabs={[
                                    {
                                        title: "Summary",
                                        content: (
                                            <InvestorDashboardSummary
                                                dashboard={result.data}
                                                onboarding={onboarding}
                                            />
                                        ),
                                        selection: "SUMMARY",
                                        updateSelection: updateMobileSelection
                                    },
                                    {
                                        title: `My investments (${result.data.investments.length})`,
                                        content: (
                                            <div ref={investmentsRef}>
                                                <InvestorDashboardInvestments
                                                    dashboard={result.data}
                                                    filter={{
                                                        ...filter,
                                                        opened: filterOpenedTable,
                                                        updateOpened: (opened: boolean) => setFilterOpenedTable(opened)
                                                    }}
                                                    onboarding={onboarding}
                                                    updateIndividualInvestmentPopup={setIndividualInvestmentPopup}
                                                    updateFilterTypesAndSource={updateFilterTypesAndSource}
                                                />
                                            </div>
                                        ),
                                        selection: "INDIVIDUAL",
                                        updateSelection: updateMobileSelection
                                    },
                                ]}
                            />
                        </>
                    ) : (
                        <div className={styles.noInvestments}>
                            <InvestorDashboardHeadNoInvestments
                                title={title}
                                updateIndividualInvestmentPopup={setIndividualInvestmentPopup}
                            />
                            <InvestorDashboardSummaryOverview
                                dashboard={result.data}
                                onboarding={onboarding}
                            />
                            <EmptyState
                                imageSrc="/empty-state/empty-state-investor-dashboard.svg"
                                textLine1="No activity found in your Dashboard"
                                textLine2="Start your investment journey NOW!"
                            />
                        </div>
                    )}
                </div>
            )}
            {isErroneous(result) && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load investor dashboard."
                />
            )}
        </>
    )
}

export default InvestorDashboard

export type MobileSelectionType = "SUMMARY" | "INDIVIDUAL"

export type FilterTypeUpdateSource = "HEAD" | "TABLE"