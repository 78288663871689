import React, {FunctionComponent, useState} from "react"
import {InvestorOnboardingType} from "../onboarding/onboarding.type"
import Head from "../../../tech/head/head.component"
import Button from "../../../tech/button/button.component"
import {ButtonSize} from "../../../tech/button/size.enum"
import {ButtonState} from "../../../tech/button/state.enum"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {sendJavaScriptError} from "../../../tech/logging/error-logger"
import SignOutIcon from "../../../tech/icon/sign-out.component"
import ProfileChangePassword from "./change-password/change-password.component"
import ProfileOnboarding from "./onboarding/onboarding.component"
import ProfileInvestor from "./investor/investor.component"
import {useApp} from "../../../tech/app/context/app.context"
import {signOut as signOutAmplify} from "aws-amplify/auth"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import {removePreviousPath} from "../../../tech/redirect/redirect.util"
import Meta from "../../../tech/meta/meta.component"
import styles from "./profile.module.sass"

type ProfileProps = {
    onboarding: InvestorOnboardingType
}

const Profile: FunctionComponent<ProfileProps> = ({
    onboarding,
}) => {
    const app = useApp()
    const [loading, setLoading] = useState<boolean>(false)

    const signOut = async () => {
        try {
            setLoading(true)
            await signOutAmplify()
            removePreviousPath()
        }
        catch (err) {
            await sendJavaScriptError(err, app.fetchClient)
        }
    }

    if (loading) {
        return <LoadingDots/>
    }

    return (
        <>
            <Meta titleExtension="Profile"/>
            <Head title="Profile"/>
            <ProfileInvestor onboarding={onboarding}/>
            <ProfileOnboarding onboarding={onboarding}/>
            <ProfileChangePassword/>
            <div className={styles.signOut}>
                <Button
                    label="Sign Out"
                    size={ButtonSize.SMALL}
                    state={ButtonState.ACTIVE}
                    style={ButtonStyle.PRIMARY}
                    type="button"
                    onClick={signOut}
                    icon={
                        <SignOutIcon
                            width={16}
                            height={16}
                            fillClass="fill-icon-white"
                        />
                    }
                />
            </div>
        </>
    )
}

export default Profile