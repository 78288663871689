import React, {FunctionComponent, useState} from "react"
import {useQuery} from "react-query"
import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"
import {Navigate, useNavigate, useParams} from "react-router-dom"
import {DealType} from "../deal.type"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Breadcrumb from "../../../tech/breadcrumb/breadcrumb.component"
import {DEAL, DEAL_INVEST, DEALS, ONBOARDING} from "../../../paths"
import InvestmentDealSummary from "../../investment/summary/summary.component"
import DealInvestmentInitializationAmount from "./amount/amount.component"
import DealInvestmentInitializationStages from "./stages/stages.component"
import DealInvestmentInitializationAction from "./action/action.component"
import {normalizeValues} from "../../../tech/form/form.util"
import DealInvestmentInitializationTerms from "./terms/terms.component"
import {QUERY__DEAL_WITH_INVESTMENT, QUERY_KEY__DEAL_WITH_INVESTMENT} from "../deal.query"
import {useApp} from "../../../tech/app/context/app.context"
import FixedContainer from "../../../tech/layout/fixed/fixed.component"
import SyndicateBanner from "../../syndicate/banner/banner.component"
import {getSyndicateOfDeal} from "../../syndicate/syndicate.util"
import styles from "./initialization.module.sass"
import Meta from "../../../tech/meta/meta.component"

type DealInvestmentInitializationProps = {
    onboarding: InvestorOnboardingType
}

const DealInvestmentInitialization: FunctionComponent<DealInvestmentInitializationProps> = ({ onboarding }) => {
    let { id } = useParams()
    const app = useApp()
    const navigate = useNavigate()
    const [state, setState] = useState<"LOADING" | "REDIRECT" | "LOADED" | "ERROR">("LOADING")
    const [deal, setDeal] = useState<DealType>()
    const [amount, setAmount] = useState<number | undefined>(0)
    const [amountErrors, setAmountErrors] = useState<string[]>([])
    const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false)

    useQuery({
        ...QUERY__DEAL_WITH_INVESTMENT(
            app.fetchClient,
            {
                keyFunction: QUERY_KEY__DEAL_WITH_INVESTMENT,
                parameters: { id: id! }
            }
        ),
        onSuccess: (data) => {
            if (data.deal instanceof DealType) {
                if (state === "LOADING") {
                    setDeal(data.deal)
                    setAmount(data.deal.minTicket)
                }
                setState("LOADED")
            }
            else {
                setState("REDIRECT")
            }
        },
        onError: (err) => {
            console.error(err)
            setState("ERROR")
        }
    })

    const invest = async () => {
        setState("LOADING")
        try {
            const investment = await app.fetchClient.dealApi.initializeInvestment(deal!.id!, normalizeValues({ amount, agreedToTerms }))
            navigate(DEAL(investment.deal.id!))
            setState("LOADED")
        }
        catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    if (onboarding.blocked) {
        navigate(ONBOARDING)
    }

    const withBreadcrumb = deal && getSyndicateOfDeal(deal, onboarding) !== undefined

    return (
        <div>
            {state === "LOADING" && <LoadingDots/>}
            {state === "REDIRECT" && <Navigate to={DEAL(id!)}/>}
            {state === "LOADED" && deal && (
                <div className={styles.initializationWrapper}>
                    <Meta titleExtension={deal.name}/>
                    <div className={styles.initialization}>
                        {withBreadcrumb && (
                            <Breadcrumb
                                backLink={DEAL(deal.id!)}
                                elements={[
                                    { text: "Deals", to: DEALS },
                                    { text: deal.name, to: DEAL(deal.id!) },
                                    { text: "Invest", to: DEAL_INVEST(deal.id!) },
                                ]}
                                maxWidth={870}
                            />
                        )}
                        <SyndicateBanner
                            syndicate={getSyndicateOfDeal(deal, onboarding)}
                            placement="MOBILE"
                        />
                        <div className={styles.content}>
                            <div className={styles.right}>
                                <FixedContainer top={withBreadcrumb ? 174 : 120}>
                                    <InvestmentDealSummary
                                        amountCommitting={amount || 0}
                                        deal={deal}
                                        investment={undefined}
                                    />
                                </FixedContainer>
                            </div>
                            <div className={styles.left}>
                                <SyndicateBanner
                                    syndicate={getSyndicateOfDeal(deal, onboarding)}
                                    placement="DESKTOP"
                                />
                                <div className={styles.complete}>
                                    Complete your investment
                                </div>
                                <DealInvestmentInitializationAmount
                                    deal={deal}
                                    amount={amount}
                                    updateAmount={(a) => {
                                        if (Number.isNaN(a)) {
                                            setAmount(undefined)
                                        }
                                        else {
                                            setAmount(a)
                                        }
                                    }}
                                    amountErrors={amountErrors}
                                    updateAmountErrors={setAmountErrors}
                                />
                                <DealInvestmentInitializationStages onboarding={onboarding}/>
                                <DealInvestmentInitializationAction
                                    amount={amount || 0}
                                    hasAmountErrors={amountErrors.length > 0}
                                    currency={deal._currency}
                                    agreedToTerms={agreedToTerms}
                                    updateAgreedToTerms={(t: boolean) => setAgreedToTerms(t)}
                                    invest={invest}
                                />
                                <DealInvestmentInitializationTerms deal={deal}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unexpected error."
                />
            )}
        </div>
    )
}

export default DealInvestmentInitialization