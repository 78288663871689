import React from "react"
import ReactDOM from "react-dom/client"
import {QueryClientProvider} from "react-query"
import { HelmetProvider } from "react-helmet-async"
import {BrowserRouter} from "react-router-dom"
import reportWebVitals from "./reportWebVitals"
import {sendToVercelAnalytics} from "./vitals"
import App from "./tech/app/App"
import AppContextProvider from "./tech/app/context/context.provider"
import {QUERY_CLIENT} from "./tech/query/query.client"
import AnalyticsWrapper from "./tech/app/analytics/analytics.component"
import {ReactQueryDevtools} from "react-query/devtools"
import ScrollToTop from "./tech/app/scroll-to-top.component"
import Meta from "./tech/meta/meta.component"
import "./index.sass"

const helmetContext = {}
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)
root.render(
  <React.StrictMode>
      <AnalyticsWrapper>
          <HelmetProvider context={helmetContext}>
              <Meta/>
              <QueryClientProvider client={QUERY_CLIENT}>
                  <AppContextProvider>
                      <BrowserRouter>
                          <ScrollToTop>
                              <App/>
                          </ScrollToTop>
                      </BrowserRouter>
                  </AppContextProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
          </HelmetProvider>
      </AnalyticsWrapper>
  </React.StrictMode>
)

reportWebVitals(sendToVercelAnalytics)