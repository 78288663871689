import React, {FunctionComponent} from "react"
import {Helmet} from "react-helmet-async"
import {getEnvironment} from "../environment/environment.util"
import {Environment} from "../environment/environment.enum"

type MetaProps = {
    titleExtension?: string
}

const Meta: FunctionComponent<MetaProps> = ({
    titleExtension
}) => {
    const title = titleExtension ? `${getTitleBase()} - ${titleExtension}` : getTitleBase()
    const description = "NonPublic unlocks exclusive deals & private equities, offering transparency, access, & liquidity. Invest in curated early to growth-stage opportunities."
    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="description"
                content={description}
            />
            <meta
                property="og:title"
                content={title}
            />
            <meta
                property="og:description"
                content={description}
            />
            <meta
                property="og:image"
                content={`${window.location.origin}/nonpublic-logo-black.png`}
            />
            <meta
                property="og:url"
                content={window.location.origin}
            />
        </Helmet>
    )
}

export default Meta

function getTitleBase() {
    switch (getEnvironment()) {
        case Environment.LOCAL:
            return "[LOCAL] NonPublic"
        case Environment.DEVELOP:
        case Environment.PREVIEW:
            return "[INT] NonPublic"
        default:
            return "NonPublic"
    }
}