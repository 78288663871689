import React, {createRef, FunctionComponent, useState} from "react"
import {InvestmentWithPaymentDetailsType} from "../../../../investment-with-payment-details.type"
import PendingInvestmentCardPaymentTable from "../table/table.component"
import commonStyles from "../../common.module.sass"
import styles from "./payment-details.module.sass"
import {PaymentDetailsAccountType} from "../../../../payment-details/payment-details.type"
import PendingInvestmentCardPaymentDetailsAccount from "./account.component"
import {ManualReconciliationBankAccountTransferOriginOpenApi} from "../../../../../../generated"

type PendingInvestmentCardPaymentDetailsProps = {
    investmentWithPaymentDetails: InvestmentWithPaymentDetailsType
}

const PendingInvestmentCardPaymentDetails: FunctionComponent<PendingInvestmentCardPaymentDetailsProps> = ({
    investmentWithPaymentDetails
}) => {
    const selectRef = createRef<HTMLSelectElement>()
    const [selectedAccount, setSelectedAccount] = useState<PaymentDetailsAccountType>(
        investmentWithPaymentDetails.paymentDetails!.accounts[0]
    )

    return (
        <div>
            <div className={styles.amountToPay}>
                {investmentWithPaymentDetails.paymentDetails?.manualReconciliation && (
                    <>
                        <div className={styles.title}>
                            Transaction Information
                        </div>
                        <div className={styles.explanation}>
                            Depending on <strong>where you transfer from</strong> you need to <strong>choose the right transfer account</strong>.
                        </div>
                        <div className={styles.selectTransferOrigin}>
                            <div>
                                <div className={commonStyles.subtitle}>
                                    Choose your transfer origin
                                </div>
                            </div>
                            <div>
                                <div>
                                    <select
                                        ref={selectRef}
                                        value={selectedAccount.number}
                                        onChange={(e) => {
                                            const account = investmentWithPaymentDetails.paymentDetails!.accounts
                                                .find(a => a.number === e.target.value)
                                            setSelectedAccount(account!)
                                        }}
                                    >
                                        {investmentWithPaymentDetails.paymentDetails!.accounts.map(account => (
                                            <option
                                                value={account.number}
                                                key={`account-select-option-${account.number}`}
                                            >
                                                {toTextRepresentation(account.manualReconciliation?.transferOrigin!)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className={`${commonStyles.subtitle} ${styles.amountToPayTitle}`}>
                    Amount To Pay
                </div>
                <PendingInvestmentCardPaymentTable input={{
                    type: "INVESTMENT_WITH_PAYMENT_DETAILS",
                    investmentWithPaymentDetails
                }}/>
                <div className={styles.alertExactAmount}>
                    You need to transfer the <strong>exact amount</strong> (Total Amount) as indicated in the cost breakup and add <strong>your
                    name</strong> in the transfer reference.
                </div>
            </div>
            <div className={styles.accountDetails}>
                <div>
                    <div className={commonStyles.subtitle}>
                        Transfer Account Details
                    </div>
                </div>
                {selectedAccount.manualReconciliation?.transferOrigin === "WITHIN_US" && (
                    <div className={styles.redNotice}>
                        <div>
                            ONLY works for transactions from US bank accounts
                        </div>
                    </div>
                )}
                {selectedAccount.manualReconciliation?.transferOrigin === "EUR_FROM_WITHIN_SEPA" && (
                    <div className={styles.redNotice}>
                        <div>
                            DO NOT use for transfers from outside the SEPA countries or currencies other than EUR
                        </div>
                    </div>
                )}
                {selectedAccount.manualReconciliation?.transferOrigin === "GBP_FROM_WITHIN_UK" && (
                    <div className={styles.redNotice}>
                        <div>
                            DO NOT use for transfers from outside the UK or currencies other than GBP
                        </div>
                    </div>
                )}
            </div>
            <PendingInvestmentCardPaymentDetailsAccount
                investment={investmentWithPaymentDetails.investment}
                paymentDetailsAccount={selectedAccount}
            />
        </div>
    )
}

export default PendingInvestmentCardPaymentDetails

function toTextRepresentation(transferOrigin: ManualReconciliationBankAccountTransferOriginOpenApi): string {
    switch (transferOrigin) {
        case "EUR_FROM_OUTSIDE_SEPA_OR_FOREIGN_CURRENCY":
            return window && window.innerWidth < 570
                ? "EUR from outside SEPA or foreign currency"
                : "Transfer EUR from outside SEPA countries or foreign currency"
        case "EUR_FROM_WITHIN_SEPA":
            return "Transfer EUR from within SEPA countries"
        case "GBP_FROM_OUTSIDE_UK_OR_FOREIGN_CURRENCY":
            return window && window.innerWidth < 570
                ? "GBP from outside UK or foreign currency"
                : "Transfer GBP from outside UK or foreign currency"
        case "GBP_FROM_WITHIN_UK":
            return "Transfer GBP from UK"
        case "OUTSIDE_US":
            return "Transfer from outside the US"
        case "WITHIN_US":
            return "Transfer from within the US"
    }
}