import React, {FunctionComponent} from "react"
import Head from "../../tech/head/head.component"
import HelpTile from "./tile/tile.component"
import HelpContact from "./contact/contact.component"
import HelpFaq from "./faq/faq.component"
import Meta from "../../tech/meta/meta.component"
import styles from "./help.module.sass"

type HelpCenterProps = {
    authenticated: boolean
}

const HelpCenter: FunctionComponent<HelpCenterProps> = ({ authenticated }) => {
    return (
        <div className={`${styles.help} ${authenticated ? styles.authenticated : styles.unauthenticated}`}>
            <Meta titleExtension="Help Center"/>
            <div className={styles.head}>
                <Head
                    title="Help Centre"
                    description="Everything you need to know about our product and how to reach out to us."
                />
            </div>
            <HelpTile
                title="FAQs"
                authenticated={authenticated}
            >
                <HelpFaq authenticated={authenticated}/>
            </HelpTile>
            <HelpTile
                title="Contact"
                authenticated={authenticated}
            >
                <HelpContact/>
            </HelpTile>
        </div>
    )
}

export default HelpCenter