import React, {useState} from "react"
import {confirmResetPassword} from "aws-amplify/auth"
import LoadingDots from "../../../loading/dots/dots.component"
import Alert from "../../../alert/alert.component"
import {AlertType} from "../../../alert/type.enum"
import SubmitButton from "../../../form/submit/submit.component"
import {SubmitHandler, useForm} from "react-hook-form"
import {ChangePasswordType} from "./change.type"
import {passwordPolicy} from "../../../form/fields/password/password.policy"
import {sendJavaScriptError} from "../../../logging/error-logger"
import AuthHead from "../../head/head.component"
import formStyles from "../../../form/form.module.sass"
import FormRow from "../../../form/row/row.component"
import EmailField from "../../../form/fields/email/email.component"
import TextField from "../../../form/fields/text/text.component"
import PasswordField from "../../../form/fields/password/password.component"
import ActionLine from "../../action-line.component"
import {useNavigate, useSearchParams} from "react-router-dom"
import {RESET_PASSWORD, SIGN_IN} from "../../../../paths"
import {addQueryParameter} from "../../../routing/parameters.util"
import {useApp} from "../../../app/context/app.context"
import styles from "./change.module.sass"
import Meta from "../../../meta/meta.component"

const ChangePassword = () => {
    const app = useApp()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [state, setState] = useState<"LOADING" | "NONE" | "ERROR">("NONE")
    const email = searchParams.get("email") || undefined
    const verificationCode = searchParams.get("c") || undefined
    const { register, handleSubmit, formState: { errors } } = useForm<ChangePasswordType>()

    const onSubmit: SubmitHandler<ChangePasswordType> = async (changePassword) => {
        setState("LOADING")
        try {
            await confirmResetPassword({
                username: changePassword.email,
                newPassword: changePassword.newPassword,
                confirmationCode: changePassword.verificationCode
            })
            navigate(addQueryParameter(SIGN_IN, { key: "changedPassword", value: "true" }))
        }
        catch (err) {
            console.error(err)
            await sendJavaScriptError(err, app.fetchClient)
            setState("ERROR")
        }
    }

    return (
        <>
            <Meta titleExtension="Set New Password"/>
            <AuthHead title="Set New Password"/>
            {state === "LOADING" && <LoadingDots/>}
            {state === "NONE" && (
                <form
                    className={formStyles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className={styles.alert}>
                        <Alert
                            type={AlertType.INFO}
                            text="Please add the verification code we sent you by email to set a new password."
                        />
                    </div>
                    <FormRow content={{
                        type: "one",
                        element: (
                            <EmailField
                                placeholder="Your Email"
                                errors={errors}
                                prefill={email}
                                reactHookFormRegister={{
                                    name: "email",
                                    register,
                                    options: {
                                        required: "Email is required."
                                    }
                                }}
                            />
                        )
                    }}/>
                    <FormRow content={{
                        type: "one",
                        element: (
                            <TextField
                                placeholder="Verification code"
                                errors={errors}
                                prefill={verificationCode}
                                reactHookFormRegister={{
                                    name: "verificationCode",
                                    register,
                                    options: {
                                        required: "Verification code is required."
                                    }
                                }}
                            />
                        )
                    }}/>
                    <FormRow content={{
                        type: "one",
                        element: (
                            <PasswordField
                                placeholder="New password"
                                errors={errors}
                                reactHookFormRegister={{
                                    name: "newPassword",
                                    register,
                                    options: passwordPolicy
                                }}
                                autoComplete="desired-password"
                            />
                        )
                    }}/>
                    <SubmitButton label="Change Password"/>
                    <ActionLine
                        intro="You have not received a verification code yet?"
                        linkText="Request one"
                        route={RESET_PASSWORD}
                        lineBreak
                        withDashedTopLine={false}
                    />
                </form>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to change password."
                />
            )}
        </>
    )
}

export default ChangePassword