import React, {useState} from "react"
import {resetPassword} from "aws-amplify/auth"
import LoadingDots from "../../loading/dots/dots.component"
import Alert from "../../alert/alert.component"
import {AlertType} from "../../alert/type.enum"
import SubmitButton from "../../form/submit/submit.component"
import {SubmitHandler, useForm} from "react-hook-form"
import {ResetPasswordType} from "./reset-password.type"
import {sendJavaScriptError} from "../../logging/error-logger"
import AuthHead from "../head/head.component"
import FormRow from "../../form/row/row.component"
import EmailField from "../../form/fields/email/email.component"
import ActionLine from "../action-line.component"
import formStyles from "../../form/form.module.sass"
import {useNavigate} from "react-router-dom"
import {addQueryParameter} from "../../routing/parameters.util"
import {CHANGE_PASSWORD} from "../../../paths"
import {useApp} from "../../app/context/app.context"
import Meta from "../../meta/meta.component"

const ForgotPassword = () => {
    const app = useApp()
    const navigate = useNavigate()
    const [state, setState] = useState<"LOADING" | "NONE" | "ERROR_GENERIC" | "ERROR_NO_USER_FOUND">("NONE")
    const { register, handleSubmit, formState: { errors } } = useForm<ResetPasswordType>()

    const onSubmit: SubmitHandler<ResetPasswordType> = async (forgotPassword) => {
        setState("LOADING")
        try {
            await resetPassword({ username: forgotPassword.email })
            navigate(addQueryParameter(CHANGE_PASSWORD, { key: "email", value: forgotPassword.email }))
        }
        catch (err) {
            console.error(err)
            await sendJavaScriptError(err, app.fetchClient)
            setState(evaluateError(err))
        }
    }

    return (
        <>
            <Meta titleExtension="Reset Password"/>
            <AuthHead title="Reset Password"/>
            {state === "LOADING" && <LoadingDots/>}
            {state === "NONE" && (
                <>
                    <form
                        className={formStyles.form}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormRow content={{
                            type: "one",
                            element: (
                                <EmailField
                                    placeholder="Your Email"
                                    errors={errors}
                                    reactHookFormRegister={{
                                        name: "email",
                                        register,
                                        options: {
                                            required: "Email is required."
                                        }
                                    }}
                                />
                            )
                        }}/>
                        <SubmitButton label="Request password reset code"/>
                        <ActionLine
                            intro="Already received a verification code?"
                            linkText="Change your password"
                            route={CHANGE_PASSWORD}
                            lineBreak
                            withDashedTopLine={false}
                        />
                    </form>
                </>
            )}
            {(state === "ERROR_GENERIC" || state === "ERROR_NO_USER_FOUND") && (
                <Alert
                    type={AlertType.ERROR}
                    text={evaluateErrorMessage(state)}
                />
            )}
        </>
    )
}

export default ForgotPassword

function evaluateError(err: unknown): "ERROR_GENERIC" | "ERROR_NO_USER_FOUND" {
    try {
        const errStr = err?.toString().toLowerCase()
        console.log("errStr", errStr)
        if (errStr?.includes("usernotfoundexception") || errStr?.includes("no registered/verified email")) {
            return "ERROR_NO_USER_FOUND"
        }
    }
    catch (ignored) {
        return "ERROR_GENERIC"
    }
    return "ERROR_GENERIC"
}

function evaluateErrorMessage(type: "ERROR_GENERIC" | "ERROR_NO_USER_FOUND"): string {
    switch (type) {
        case "ERROR_GENERIC":
            return "Failed to send verification code for password reset. Please try again."
        case "ERROR_NO_USER_FOUND":
            return "There is no registered and verified user with that email."
    }
}

