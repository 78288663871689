import React, {FunctionComponent} from "react"
import {formatAmountWithCurrency} from "../../../../tech/format/amount.util"
import {InvestmentType} from "../../../investment/investment.type"
import ExclamationMarkIcon from "../../../../tech/icon/exclamation-mark.component"
import {evaluateStage} from "../../../investment/pending/stage/stage.util"
import {useQuery} from "react-query"
import {QUERY__INVESTOR_ONBOARDING, QUERY_KEY__INVESTOR_ONBOARDING} from "../../../investor/onboarding/onboarding.query"
import {useApp} from "../../../../tech/app/context/app.context"
import styles from "./investment-note.module.sass"
import {InvestmentStage} from "../../../investment/pending/stage/stage.type"

type InvestmentCommitmentProps = {
    investment: InvestmentType
}

const DealCardInvestmentNote: FunctionComponent<InvestmentCommitmentProps> = ({ investment }) => {
    const app = useApp()

    const { data } = useQuery(QUERY__INVESTOR_ONBOARDING(
        app.fetchClient,
        QUERY_KEY__INVESTOR_ONBOARDING(),
        false
    ))

    const stage = evaluateStage(investment, data!)
    const text = assembleText(stage)

    return (
        <div className={styles.note}>
            <div className={styles.title}>
                You have {stage === "FINALIZED" ? "invested" : "committed"} {formatAmountWithCurrency(investment.amount, investment.deal._currency)}
            </div>
            {text && (
                <div className={styles.alert}>
                    <div className={styles.icon}>
                        <ExclamationMarkIcon
                            width={16}
                            height={16}
                            fillClass="fill-icon-caption"
                        />
                    </div>
                    <div className={styles.text}>
                        {text}
                    </div>
                </div>
            )}
        </div>
    )
}

export default DealCardInvestmentNote


function assembleText(stage: InvestmentStage): string | undefined {
    switch (stage) {
        case "SIGN_DOCUMENTS":
            return "You need to sign the required documents."
        case "WAIT_FOR_MINIMUM_AMOUNT":
            return "Waiting for minimum amount to be reached."
        case "WAIT_FOR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER":
            return "Preparing payment instructions."
        case "MAKE_PAYMENT":
            return "You need to pay the committed amount."
        case "WAIT_FOR_CLOSING":
            return "Waiting for deal closing."
    }
}